import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image";
import {
  Section,
  SectionWrapper,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import ApplyInterFont from "../../components/campaigns/ApplyInterFont";
import { OnDesktop } from "../../components/DesktopMobileComps";
import { Button } from "../../components/core/Button";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, HeroImgContainer, HeroSectionContainer, HeroSectionData, HeroSectionHeading, ModalContainer, QuoteModalPlaceholder } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const StepsToProtectBusiness = loadable(() => import("../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const ProtectionAgainstRisks = loadable(() => import("../../components/campaigns/glp8/ProtectAgainstRisks"))
const QuotesFromCompanies = loadable(() => import("../../components/campaigns/glp6/QuotesFromCompanies"))
const FactsSection = loadable(() => import("../../components/campaigns/glp6/FactsSection"))
const ReviewsSection = loadable(() => import("../../components/campaigns/glp6/ReviewsSection"))
const ExtraBenefits = loadable(() => import("../../components/campaigns/glp6/ExtraBenefits"))
const FooterSection = loadable(() => import("../../components/campaigns/glp6/FooterSection"))

const IndexPage = () => {
  const mixpanel = useMixpanel();
  
  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  React.useEffect(() => {
    document.addEventListener('touchstart', () => {}, {passive: true});
  }, [])

  const heroSectionDefaultComp = (
    <>
      <StyledHeroSectionHeading>
        Insure your business starting at<br /> <span style={{color: "rgba(79, 118, 232)"}}>just ₹8 per day!</span>
      </StyledHeroSectionHeading>
      {!hideQuoteCTAs && <OnDesktop><Button type="flatdesign" uppercaseLabel={false} style={{padding: "12px 20px"}} label="Get a Quote" onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} /></OnDesktop>}
      <StyledHeroImgContainer>
        <StaticImage 
          src="../../assets/images/campaigns/glp8-hero.webp"
          alt="secure business starting at 8 rs per day"
          width={522}
          height={310}
          loading="eager"
          placeholder="none"
        />
      </StyledHeroImgContainer>
    </>
  )

  return (
    <>
      <ApplyInterFont />
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe} 
        showCallHelplineCTA={isSelfServe} 
        newTheme 
        handleGetQuoteClick={scrollToLeadForm}
        handleCallScheduleClick={handleCallScheduleClick}
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{display: isSelfServe ? "none" : "block", marginTop: '4rem', position: "relative"}}>
          <StyledHeroBg />
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: "0px"}} desktopStyles={{paddingTop: "50px"}}>
            <HeroSectionData>
              <StyledHeroSectionContainer>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </StyledHeroSectionContainer>
              <StyledModalContainer ref={leadformWrapperRef}>
                <LeadForm 
                  newTheme 
                  ref={leadformRef}
                  formType="google" 
                  renderAsPopup={false} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />}
                  setHeroSectionScreen={setScreen} 
                />
              </StyledModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness style={{background: "#fff"}} /> : null}
      <ProtectionAgainstRisks />
      <FactsSection mobileStyles={{background: "#F6F9FF"}} desktopStyles={{background: "#fff"}} />
      <QuotesFromCompanies mobileStyles={{background: "#fff"}} desktopStyles={{background: "#F6F9FF"}} />
      <ReviewsSection 
        mobileStyles={{background: "#F6F9FF"}} 
        desktopStyles={{background: "#fff"}} 
        handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)} 
      />
      <ExtraBenefits 
        pageName={pageName}
        desktopStyles={{background: "#FFF"}} 
        mobileStyles={{background: "#FFF"}} 
      />
      <FooterSection 
        pageName={pageName}
        mobileStyles={{background: "#F6F9FF"}}
        desktopStyles={{background: "#fff"}}
        handleGetQuoteClick={(e, ctaNumber=4) => handleGetQuoteClick(e, ctaNumber)} 
      />
      <Footer style={{background: "#fff"}} />
    </>
  );
};

const StyledHeroBg = styled(HeroBg)`
  background: rgba(233, 239, 254, 0.4);
`

const StyledHeroSectionContainer = styled(HeroSectionContainer)`
  .limited-offer {
    position: absolute;
    left: -24px;
  }
  @media (max-width: 768px) {
    display: block;
    .limited-offer {
      left: -40px;
      width: 80px;
      height: 80px;
    }
  }
`

const StyledHeroImgContainer = styled(HeroImgContainer)`
  width: min(522px, 100%);
  margin-top: 2.5rem;
  @media screen and (max-width: 768px) {
    width: min(254px, 100%);
    margin: auto;
    margin-top: 0.4rem;
  }
`

const StyledModalContainer = styled(ModalContainer)`
  width: min(520px, 100%);
`

const StyledHeroSectionHeading = styled(HeroSectionHeading)`
  font-family: Inter;
  font-size: 30px;
  line-height: 60px;
  font-weight: 700;
  flex: 1;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`;

export default IndexPage;
